<template>
<!--    <div class="min-vh-100 p-3">-->
<!--    <div class="py-3 pl-3 pr-0 bg-info">-->
    <div class="my-3 ml-3 pr-0 bg-primary-light">
        <table-toolbar
            :title="$tc('organisation.organisation', 2)"
            class="bg-primary p-1"
            style="height: 45px"
        ></table-toolbar>

        <b-table striped hover bordered
                 table-class="text-center"
                 table-variant="white"
                 :fields="columns"
                 :items="items"
                 thead-tr-class="bg-secondary"
        >
<!--                 thead-class="bg-secondary"-->
<!--                 head-row-variant="transparent"-->

            <!-- actions head -->
            <!--<template #head(action)="row">
                <b-icon-plus-square
                    style="cursor: pointer;"
                    variant="danger"
                    scale="1.2"
                    v-b-tooltip.hover
                    :title="$t('organisation.hover_add_contact')"
                    @click="addContact(row)"
                ></b-icon-plus-square>
            </template>-->

            <!-- actions -->
            <template v-slot:cell(action)="row">
                <div>
                    <b-icon-trash
                        style="cursor: pointer;"
                        variant="danger"
                        scale="1.3"
                        v-b-tooltip.hover
                        :title="$t('organisation.hover_delete_contact')"
                        @click="removeOrganisation(row)"
                    ></b-icon-trash>
                </div>
            </template>

            <!-- organisations -->
            <template v-slot:cell(name)="row">
                {{row.item.name}}
            </template>

            <template v-slot:cell(description)="row">
                {{row.item.description}}
            </template>

            <template v-slot:cell(tourism)="row">
                <b-icon font-scale="1.5" variant="success" icon="check"></b-icon>
            </template>

            <template v-slot:cell(phone)="row">
                {{row.item.phone}}
            </template>

            <template v-slot:cell(certificate)="row">
                {{row.item.certificate}}
            </template>

            <template v-slot:cell(edit)="row">
                <b-icon-file-text
                    size="sm"
                    style="cursor: pointer;"
                    variant="secondary"
                    scale="1.3"
                    v-b-tooltip.hover
                    class="align-middle"
                    @click="recordEdit(row.item)"
                >
                </b-icon-file-text>
                <FormOrganisation></FormOrganisation>
            </template>
        </b-table>
    </div>
</template>

<script>
import FormOrganisation from "./ModalFormOrganisation";
import TableToolbar from "@/components/general/elements/tableAccessories/TableToolbar";

export default {
    name: "Organisation",
    components: {TableToolbar, FormOrganisation},
    props: {},
    data() {
        return {
            selected: '',
            columns: [
                {
                    key: 'name',
                    label: this.$t('organisation.name'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'description',
                    label: this.$t('organisation.contact_description'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'tourism',
                    label: this.$t('organisation.farm_tourism'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'phone',
                    label: this.$t('organisation.phone_default'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'certificate',
                    label: this.$tc('organisation.certificate', 2),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'edit',
                    label: this.$t('form.edit'),
                    sortable: false,
                    show: true,
                    thStyle: {'vertical-align': 'middle'}
                },
                {
                    key: 'action',
                    label: '',
                    thStyle: {'vertical-align': 'middle', width: '55px'},
                    tdClass: 'align-middle',
                    tdStyle: {'vertical-align': 'middle'}
                },
            ],

            items: [
                {
                    name: 'Kmetija Bolfenk',
                    description: 'Big Boss',
                    tourism: null,
                    phone: '0038641555555',
                    certificate: 'Ekološka kmetija',
                },
            ],
            table: {
                items: [],
                fields: [],
                sortBy: 'id',
                sortDesc: false,
            },
        }
    },
    mounted() {
    },
    methods: {
        removeOrganisation(row) {
        },
        recordEdit(row) {
            this.$bvModal.show('modalFormOrganisation');
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
