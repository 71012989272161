<template>
    <b-modal
        id="modalFormOrganisation"
        :title="$tc('organisation.organisation', 1)"
        size="xl"
        :ok-title="$t('form.save')"
        :cancel-title="$t('form.cancel')"
    >
        <b-form-row ref="form" @submit.prevent="submit">
            <b-col cols="8">
                <!-- organisation type -->
                <b-form-group
                    id="group-organisation_type_id"
                    class="col-sm-12 col-lg-10 col-xl-9"
                    label-class="mb-0"
                    :label="$t('organisation.type_organisation')"
                    label-for="organisation_type_id"
                    description=""
                    :state="valid_organisation_type"
                >
                    <b-form-select
                        id="organisation_type_id"
                        v-model="record.organisation_type_id"
                        :options="organisation_type"
                        required
                        :state="valid_organisation_type"
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col style="min-height: 35px" cols="4" class="text-right text-center">
                <!-- id, active -->
                <b-form-group
                    class="mb-0"
                    :label="record.id === 0 ? '' : $t('form.id_value', {value: record.id})"
                    label-size=""
                    label-class="pt-0"
                    label-cols="6"
                >
                    <b-form-checkbox
                        id="active"
                        class="float-right align-middle"
                        switch
                        v-model="record.active"
                        name="active"
                        value="true"
                        unchecked-value="false"
                    >
                        <b>{{$t('form.publish')}}</b>
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-form-row>
        <!-- tabs -->
        <b-card no-body>
            <b-tabs pills card vertical>

                <!-- basic info -->
                <b-tab :title="$t('form.tab.basic')">
                    <b-card-text>
                        <b-row>

                            <!-- BASIC INFO -->
                            <b-col>
                                <b-form-group
                                    :label="$t('form.basic.data')"
                                    label-size="md"
                                    label-class="font-weight-bold pt-0"
                                    class="mb-0"
                                >
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('organisation.name_official')}}:</label>
                                        <b-form-input
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="organisation_name_official"
                                            type="text"
                                            required
                                            :placeholder="$t('organisation.name_official_placeholder')"
                                        ></b-form-input>
                                    </b-form>
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('organisation.name_commercial')}}:</label>
                                        <b-form-input
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="organisation_name_official"
                                            type="text"
                                            required
                                            :placeholder="$t('organisation.name_commercial_placeholder')"
                                        ></b-form-input>
                                    </b-form>
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('organisation.tax_number')}}:</label>
                                        <b-form-input
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="organisation_tax_number"
                                            required
                                            :placeholder="$t('organisation.tax_number_placeholder')"
                                        ></b-form-input>
                                    </b-form>
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{this.$tc('register.taxpayer_type', 1)}}:</label>
                                        <b-form-select
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="taxpayer_type"
                                            v-model="record.basic.taxpayer_type_id"
                                            :options="data.taxpayer_types"
                                        ></b-form-select>
                                    </b-form>
                                    <b-form class="mb-3">
                                        <label
                                            class="mr-2 font-weight-bold text-secondary">{{$t('organisation.status')}}:</label>
                                        <b-form-select
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="taxpayer_type"
                                            v-model="record.taxpayer_type"
                                            :options="data.status"
                                        ></b-form-select>
                                    </b-form>
                                </b-form-group>
                            </b-col>

                            <b-col>
                                <!-- BANK INFO -->
                                <b-form-group
                                    :label="$t('organisation.bank_account')"
                                    label-size="md"
                                    label-class="font-weight-bold pt-0"
                                    class="mb-0"
                                >
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('organisation.bank_name')}}:</label>
                                        <b-form-select
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="organisation_bank_name"
                                            v-model="record.basic.bank"
                                            :options="data.banks"
                                        ></b-form-select>
                                    </b-form>
                                    <b-form class="mb-3" v-if="record.basic.bank===-1">
                                        <label class="mr-2">{{$t('organisation.bank_name_other')}}:</label>
                                        <b-form-input
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="organisation_bank_account_number"
                                            required
                                            :placeholder="$t('organisation.bank_name_placeholder')"
                                        ></b-form-input>
                                    </b-form>
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('organisation.bank_account_number')}}:</label>
                                        <b-form-input
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="organisation_bank_account_number"
                                            required
                                            :placeholder="$t('organisation.bank_account_number_placeholder')"
                                        ></b-form-input>
                                    </b-form>
                                </b-form-group>

                                <!-- FARM INFO  -->
                                <b-form-group
                                    :label="$tc('organisation.data_farm', 1)"
                                    label-size="md"
                                    label-class="font-weight-bold pt-0"
                                    class="mb-0 mt-4"
                                >
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('organisation.farm_number')}}:</label>
                                        <b-form-input
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="organisation_bank_account_number"
                                            :placeholder="$t('organisation.farm_number_placeholder')"
                                        ></b-form-input>
                                    </b-form>

                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('organisation.farm_acreage')}}:</label>
                                        <b-input-group append="ha">
                                            <b-form-input
                                                class="mb-sm-0 text-right"
                                                id="organisation_farm_acreage"
                                                placeholder="..."
                                            ></b-form-input>
                                        </b-input-group>
                                    </b-form>

                                    <b-form-checkbox
                                        id="checkbox_tourism"
                                        name="Tourism"
                                        value="not_accepted"
                                    >
                                        {{$t('organisation.farm_tourism')}}
                                    </b-form-checkbox>

                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>

                <!-- address -->
                <b-tab :title="$t('organisation.tab.address')">
                    <b-card-text>
                        <b-row>
                            <b-col>

                                <!--address-->
                                <b-form-group
                                    :label="$t('organisation.address')"
                                    label-size="md"
                                    label-class="font-weight-bold pt-0"
                                    class="mb-0"
                                >
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('organisation.state')}}:</label>
                                        <b-form-select
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="organisation_state"
                                            v-model="record.contact.state"
                                            :options="data.state"
                                        ></b-form-select>
                                    </b-form>
                                    <b-form class="mb-3" v-if="record.contact.state===-1">
                                        <label class="mr-2">{{$t('organisation.address')}}:</label>
                                        <b-form-textarea
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="organisation_address_other"
                                            :placeholder="$t('organisation.address_placeholder')"
                                        ></b-form-textarea>
                                    </b-form>
                                    <b-form-group v-else="record.contact.state===-1">
                                        <b-form class="mb-3">
                                            <label class="mr-2">{{$t('organisation.address')}}:</label>
                                            <b-form-input
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="organisation_street"
                                                required
                                                :placeholder="$t('organisation.street_placeholder')"
                                            ></b-form-input>
                                        </b-form>
                                        <b-form class="mb-3">
                                            <label class="mr-2">{{$t('organisation.post')}}:</label>
                                            <b-form-select
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="organisation_post"
                                                required
                                                v-model="record.contact.post"
                                                :options="data.post"
                                            ></b-form-select>
                                        </b-form>
                                        <b-form class="mb-3">
                                            <label class="mr-2">{{$t('organisation.region')}}:</label>
                                            <b-form-select
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                id="organisation_city"
                                                required
                                                v-model="record.contact.region"
                                                :options="data.region"
                                            ></b-form-select>
                                        </b-form>
                                    </b-form-group>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <!--   Other  -->
                                <b-form-group
                                    :label="$t('organisation.other')"
                                    label-size="md"
                                    label-class="font-weight-bold pt-0"
                                    class="mb-0"
                                >
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('organisation.webpage')}}:</label>
                                        <b-form-input
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="organisation_url"
                                            type="url"
                                            :placeholder="$t('organisation.webpage_placeholder')"
                                            :tooltip="$t('organisation.webpage_tooltip')"
                                        ></b-form-input>
                                    </b-form>
                                    <b-form class="mb-3">
                                        <label class="mr-2">{{$t('organisation.liaison')}}:</label>
                                        <b-form-input
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            id="organisation_liaison"
                                            type="text"
                                            required
                                            :placeholder="$t('organisation.liaison_placeholder')"
                                            :tooltip="$t('organisation.liaison_tooltip')"
                                        ></b-form-input>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>

                <!--   contacts  -->
                <b-tab :title="$t('organisation.tab.contact')">
                    <b-card-text>
                        <b-form-group
                            :label="$t('organisation.tab.contact')"
                            label-size="md"
                            label-class="font-weight-bold pt-0"
                            class="mb-0"
                        >
                            <form-organisation-contacts></form-organisation-contacts>
                        </b-form-group>
                    </b-card-text>
                </b-tab>

                <!--   employees  -->
                <b-tab :title="$t('organisation.tab.employees')">
                    <b-card-text>
                        <b-form-group
                            :label="$t('organisation.tab.employees')"
                            label-size="md"
                            label-class="font-weight-bold pt-0"
                            class="mb-0"
                        >
                        </b-form-group>
                    </b-card-text>
                </b-tab>

                <!-- introduction -->
                <b-tab :title="$t('organisation.tab.introduction')">
                    <b-card-text>
                        <b-form-group
                            :label="$t('organisation.tab.introduction')"
                            label-size="md"
                            label-class="font-weight-bold pt-0"
                            class="mb-0"
                        >
                            <vue-editor class="editor-blue"></vue-editor>
                        </b-form-group>
                    </b-card-text>
                </b-tab>

                <!-- tourism -->
                <b-tab :title="$t('organisation.tab.tourism')">
                    <b-card-text>
                        <b-form-group
                            :label="$t('organisation.tab.tourism')"
                            label-size="md"
                            label-class="font-weight-bold pt-0"
                            class="mb-0"
                        >
                            <vue-editor class="editor-yellow"></vue-editor>
                        </b-form-group>
                    </b-card-text>
                </b-tab>

                <!-- certificates -->
                <b-tab :title="$t('organisation.tab.certificate')">
                    <b-card-text>
                        <b-form-group
                            :label="$t('organisation.tab.certificate')"
                            label-size="md"
                            label-class="font-weight-bold pt-0"
                            class="mb-0"
                        >
                            <form-organisation-certificates></form-organisation-certificates>
                        </b-form-group>
                    </b-card-text>
                </b-tab>
                <!-- image -->
                <b-tab :title="$t('form.tab.picture')">
                    <b-card-text>
                        <b-form-group
                            :label="$t('form.tab.picture')"
                            label-size="md"
                            label-class="font-weight-bold pt-0"
                            class="mb-0"
                        >
                            Slike
                        </b-form-group>
                    </b-card-text>
                </b-tab>
                <!-- notes -->
                <b-tab :title="$t('form.tab.notes')">
                    <b-card-text>
                        <b-form-group
                            :label="$t('form.tab.notes')"
                            label-size="md"
                            label-class="font-weight-bold pt-0"
                            class="mb-0"
                        >
                            <vue-editor class="editor-red"></vue-editor>
                        </b-form-group>
                    </b-card-text>

                </b-tab>
            </b-tabs>
        </b-card>
    </b-modal>
</template>

<script>
import FormOrganisationContacts from "@/components/manager/organisation/FormOrganisationContacts";
import FormOrganisationCertificates from "@/components/manager/organisation/FormOrganisationCertificates";

export default {
    name: "FormOrganisation",
    components: {FormOrganisationCertificates, FormOrganisationContacts},
    props: {
        form: {
            valid: false,
            record: {},
        },
        // data: {},
        modalBus: Object,
    },
    data() {
        return {
            valid: null,
            data: {
                legal_status: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Kmet pavšalist',
                    },
                    {
                        value: 2,
                        text: 'Mali zavezanec za DDV',
                    },
                    {
                        value: 3,
                        text: 'Ni zavezanec za DDV',
                    },
                ],
                status: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Ponudnik',
                    },
                    {
                        value: 2,
                        text: 'Neplačnik',
                    },
                ],
                banks: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'ADDIKO BANK D.D.',
                    },
                    {
                        value: 2,
                        text: 'BANKA INTESA SANPAOLO D.D.',
                    },
                    {
                        value: 3,
                        text: 'Banka Slovenije',
                    },
                    {
                        value: -1,
                        text: 'Drugo',
                    },
                ],
                state: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Slovenija',
                    },
                    {
                        value: 2,
                        text: 'ZDA',
                    },
                    {
                        value: 3,
                        text: 'Savdska Arabija',
                    },
                    {
                        value: -1,
                        text: 'Drugo',
                    },
                ],
                post: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: '9244 Sveti Jurij ob Ščavnici',
                    },
                    {
                        value: 2,
                        text: '2000 Maribor',
                    },
                    {
                        value: 3,
                        text: '8000 Murska Sobota',
                    },
                ],
                region: [
                    {
                        value: null,
                        text: this.$t('form.choose_placeholder'),
                    },
                    {
                        value: 1,
                        text: 'Podravska',
                    },
                    {
                        value: 2,
                        text: 'Pomurska',
                    },
                    {
                        value: 3,
                        text: 'Spodnja primorska',
                    },
                ],
            },
            record: {
                id: 0,
                active: false,
                order: 0,
                basic: {
                    taxpayer_type: null,
                    status: null,
                    bank: null,
                },
                contact: {
                    state: null,
                    post: null,
                    city: null,
                    region: null,
                },
                organisation_type_id: null,
                meta: {
                    name: '',
                    name_full: ''
                },
                content: {
                    code: '',
                    domain: '',
                    mark_2: '',
                    mark_3: '',
                    eu_member: false
                },
                locale: {},
            },
            organisation_type: [],
            // continents: [],
        }
    },
    mounted() {
        // this.modalBus.$on('submit', this.submit);
        // <-- set foreign data -->
        // this.setContinents();
        // <--  -->
        // if (this.form.record != null) {
        //     this.$set(this, 'record', this.form.record);
        // }
    },
    methods: {
        // <-- set selects data -->
        setContinents() {
            // let continents = [
            //     {value: null, text: '/', disabled: false},
            // ];
            // let meta = false;
            // let locale = {};
            // this.data.continents.forEach((continent, index) => {
            //     meta = false;
            //     locale = continent.locale[this.$i18n.locale];
            //     // <-- if missing locale data -->
            //     if (locale === undefined) {
            //         meta = true;
            //         locale = continent.meta;
            //     }
            //     continents.push(
            //         {
            //             value: continent.id,
            //             text: locale.name + (meta ? this.$t('form.meta.message', {msg: this.$t('name')}) : ''),
            //             disabled: false
            //         }
            //     );
            // });
            // // <--  -->
            // this.$set(this, 'continents', continents);
        },
        // <-- form -->
        submit() {
            if (this.$refs.form === undefined) {
                return;
            }
            this.valid = this.$refs.form.checkValidity();

            this.$set(this.form, 'record', this.record);
            this.$set(this.form, 'valid', this.valid);
        }
    },
    computed: {
        activeText: {
            get() {
                return !this.record.active || this.record.active === 'false' || this.record.active === undefined
                    ? this.$t('form.inactive')
                    : this.$t('form.active');
            }
        },
        setRecordLocale() {
            this.data.locale.forEach((value, index) => {
                // <-- add missing locale record -->
                if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
                    this.$set(this.record.locale, value.locale, {
                        name: '',
                        name_full: '',
                        notes: ''
                    });
                }
            });
            return this.record.locale;
        },
        valid_continent() {
            if (this.valid === null) {
                return null;
            }
            return this.record.continent_id > 0 ? null : false;
        },
        // <-- meta -->
        valid_metaName() {
            if (this.valid === null) {
                return null;
            }
            return this.record.meta.name.length > 0 ? null : false;
        },
        valid_organisation_type() {
            // if (this.valid === null) {
            //     return null;
            // }
            // return this.record.meta.name.length > 0 ? null : false;
            return false;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
</style>
