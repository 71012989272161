<template>
    <div>
        <b-table
            :fields="columns"
            :items="items"
            bordered
            head-row-variant=""
            hover
            striped
            table-class="text-center"
            table-variant="light"
        >
            <!-- actions head -->
            <template #head(action)="row">
                <b-icon-plus-square
                    v-b-tooltip.hover
                    :title="$t('organisation.hover_add_certificate')"
                    scale="1.2"
                    style="cursor: pointer;"
                    variant="danger"
                    @click="addCertificate()"
                ></b-icon-plus-square>
            </template>

            <!-- actions -->
            <template v-slot:cell(action)="row">
                <div class="d-flex justify-content-around">
                    <b-icon-trash
                        v-b-tooltip.hover
                        scale="1.3"
                        style="cursor: pointer;"
                        variant="primary"
                        @click="removeCertificate(row)"
                    ></b-icon-trash>
                </div>
            </template>

            <!-- certificates -->
            <template v-slot:cell(organisation)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.organisation"
                        :placeholder="$t('organisation.certificate_organisation_placeholder')"
                        class="text-center"
                        size="sm"
                        type="text"
                        variant="primary"
                    ></b-form-input>
                </div>
            </template>

            <template v-slot:cell(name)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.name"
                        :placeholder="$t('organisation.certificate_name_placeholder')"
                        class="text-center"
                        size="sm"
                        type="text"
                        variant="primary"
                    ></b-form-input>
                </div>
            </template>

            <template v-slot:cell(number)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.number"
                        :placeholder="$t('organisation.certificate_number_placeholder')"
                        class="text-center"
                        size="sm"
                        variant="primary"
                    ></b-form-input>
                </div>
            </template>

            <template v-slot:cell(expiration)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.expiration"
                        :placeholder="$t('organisation.certificate_expires_placeholder')"
                        class="text-center"
                        size="sm"
                        type="date"
                        variant="primary"
                    ></b-form-input>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    name: "FormOrganisationCertificates",
    components: {},
    props: {},
    data() {
        return {
            selected: '',
            columns: [
                {
                    key: 'organisation',
                    label: this.$t('organisation.certificate_organisation'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'}
                },
                {
                    key: 'number',
                    label: this.$t('organisation.certificate_number'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'}
                },
                {
                    key: 'name',
                    label: this.$t('organisation.certificate_name'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'}
                },
                {
                    key: 'expiration',
                    label: this.$t('organisation.certificate_expires'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle', width: '130px'}
                },
                {
                    key: 'action',
                    label: '',
                    thStyle: {width: '55px'},
                    tdClass: 'align-middle'
                },
            ],

            items: [
                {
                    organisation: 'IKC Maribor',
                    name: 'Big Boss',
                    number: 22546584654,
                    expiration: '12.12.2021',
                },
                {
                    organisation: 'IKC Maribor',
                    name: 'Big Boss',
                    number: 22546584654,
                    expiration: '12.12.2021',
                },
                {
                    organisation: 'IKC Maribor',
                    name: 'Big Boss',
                    number: 22546584654,
                    expiration: '12.12.2021',
                },
                {
                    organisation: null,
                    name: null,
                    number: null,
                    expiration: null,
                },
            ],
            table: {
                items: [],
                fields: [],
                sortBy: 'id',
                sortDesc: false,
            },


        }
    },
    mounted() {
    },
    methods: {
        addCertificate() {
        },
        removeCertificate(row) {
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
