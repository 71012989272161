<template>
    <div>
        <b-table striped hover bordered
                 table-class="text-center"
                 head-row-variant=""
                 table-variant="light"
                 :fields="columns"
                 :items="items"
        >

            <!-- actions head -->
            <template #head(action)="row">
                <b-icon-plus-square
                    style="cursor: pointer;"
                    variant="danger"
                    scale="1.2"
                    v-b-tooltip.hover
                    @click="addContact(row)"
                ></b-icon-plus-square>
            </template>

            <!-- actions -->
            <template v-slot:cell(action)="row">
                <div class="d-flex justify-content-around">
                    <b-icon-trash
                        style="cursor: pointer;"
                        variant="primary"
                        scale="1.3"
                        v-b-tooltip.hover
                        @click="deleteContact(row)"
                    ></b-icon-trash>
                </div>
            </template>
            <!--              @click="recordDelete(row.item)"-->

            <!-- contacts -->
            <template v-slot:cell(description)="row">
                <div class="d-flex justify-content-around">
                    <!--          {{row.item.description}}-->
                    <b-form-input
                        v-model="row.item.description"
                        variant="primary"
                        type="text"
                        :placeholder="$t('organisation.description_placeholder')"
                        size="sm"
                        class="text-center"
                    ></b-form-input>
                </div>
            </template>

            <template v-slot:cell(number)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.number"
                        variant="primary"
                        :placeholder="$t('organisation.phone_placeholder')"
                        size="sm"
                        class="text-center"
                        type="text"
                    ></b-form-input>
                </div>
            </template>

            <template v-slot:cell(email)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.email"
                        variant="primary"
                        :placeholder="$t('organisation.email_placeholder')"
                        size="sm"
                        class="text-center"
                        type="text"
                    ></b-form-input>
                </div>
            </template>

            <template v-slot:cell(primary)="row">
                <div class="d-flex justify-content-around">
                    <b-form-radio v-model="selected"
                                  value="row.primary">
                    </b-form-radio>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script lang="">
export default {
    name: "FormOrganisationContacts",
    components: {},
    props: {},
    data() {
        return {
            selected: '',
            columns: [
                {
                    key: 'description',
                    label: this.$t('organisation.contact_description'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align':'middle'}
                },
                {
                    key: 'number',
                    label: this.$t('organisation.contact_number'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align':'middle'}
                },
                {
                    key: 'email',
                    label: this.$t('organisation.contact_email'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align':'middle'}
                },
                {
                    key: 'primary',
                    label: this.$t('organisation.contact_primary'),
                    sortable: true,
                    show: true,
                    tdClass: 'krneki',
                    thStyle: {'vertical-align':'middle'}

                },
                {
                    key: 'action',
                    label: '',
                    thStyle: {'vertical-align':'middle', width: '55px'},
                    tdClass: 'align-middle'
                },
            ],

            items: [
                {
                    description: '',
                    number: '',
                    email: '',
                    primary: null,
                },
                {
                    description: 'Boss\'s wife',
                    number: '041555555',
                    email: 'leann.colbert@protonmail.com',
                    primary: null,
                },
                {
                    description: 'Big Boss',
                    number: '041555555',
                    email: 'leann.colbert@protonmail.com',
                    primary: null,
                },
                {
                    description: 'Boss wife',
                    number: '041555555',
                    email: 'miss.vodopivec@protonmail.com',
                    primary: null,
                },
            ],
            table: {
                items: [],
                fields: [],
                sortBy: 'id',
                sortDesc: false,
            },
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
</style>
